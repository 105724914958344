<template>
  <div class="sidebar" :class="{ active: showMenu}">
    <div class="title has-text-centered">
      <font-awesome-icon v-if="icon" :icon="icon" class="has-text-danger"/>&nbsp;&nbsp;
      <span class="has-text-danger">{{title}}</span>
      <span class="is-pulled-left">
        <a class="has-text-grey is-size-4" @click="emitToggle"><font-awesome-icon icon="times-circle"/></a>
      </span>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'sideBar',
  props: ['icon', 'title'],
  inject: ['$validator'],
  data: function () {
    return {
      active: false,
      showMenu: false
    }
  },
  mounted () {
    this.active = true
  },
  methods: {
    emitToggle () {
      this.showMenu = false
      _.delay(() => {
        this.$emit('toggle', '')
      }, 1000)
    }
  },
  watch: {
    active () {
      if (this.active) {
        _.delay(() => {
          this.showMenu = true
        }, 50)
      }
    },
    $route (to, from) {
      this.emitToggle();
    }
  }
}
</script>

<style scoped lang="scss">
  @import "@/assets/style/screen-size.scss";

  .title {
    padding: 5px 1px;
    font-size: 1.7em;
    border-bottom: 2px solid lightgray;
    min-height: 1.5em;
  }

  .sidebar {
    height: 100%;
    width: 0px;
    position: fixed;
    right: 0;
    top: 0;
    background-color: hsl(0, 0%, 86%);
    z-index: 999;
    box-shadow: -10px 0 5px -2px rgba(0, 0, 0, 0.4);
    transition: width 0.8s ease-in-out;
    -webkit-transition: width 0.8s ease-in-out;
    overflow-y: scroll;
    padding-bottom: 2em;
    overscroll-behavior: none;

    &.active {
      width: 30%;
      @media screen and (min-width: $tablet) and (max-width: $desktop) {
        width: 50%;
      }

      @media screen and (max-width: $tablet) {
        width: 85%;
      }

      &.is-large {
        width: 75%;

        @media screen and (min-width: $tablet) and (max-width: $desktop) {
          width: 85%;
        }

        @media screen and (max-width: $tablet) {
          width: 90%;
        }
      }
    }
  }
</style>
